import {ISearchResult} from '@app/_modules/_shared/_interfaces/search-strategy.interface';

export interface AddressEntity {
  city?: string;
  address: string;
}

export interface NumberPhoneEntity {
  number: string;
}

export interface EmailEntity {
  email: string;
}

export enum GenderTypeEnum {
  MALE = 'M',
  FEMALE = 'F',
  OTHER = 'O'
}

export const GenderType2LabelMapping:
  Record<GenderTypeEnum, string> = {
  [GenderTypeEnum.MALE]: 'Masculino',
  [GenderTypeEnum.FEMALE]: 'Femenino',
  [GenderTypeEnum.OTHER]: 'Otro',
}

export enum IdentificationTypeEnum {
  RUC = '04',
  CEDULA = '05',
  PASAPORTE = '06',
  CONSUMIDOR_FINAL = '07',
  IDE = '08',
}

export const IdentificationType2LabelMapping:
  Record<IdentificationTypeEnum, string> = {
  [IdentificationTypeEnum.RUC]: 'RUC',
  [IdentificationTypeEnum.CEDULA]: 'Cédula',
  [IdentificationTypeEnum.PASAPORTE]: 'Pasaporte',
  [IdentificationTypeEnum.CONSUMIDOR_FINAL]: 'Consumidor final',
  [IdentificationTypeEnum.IDE]: 'ID DEL EXTERIOR',
}

export enum CustomerTypeEnum {
  PERSON = 'P',
  COMPANY = 'C'
}

export const CustomerType2LabelMapping:
  Record<CustomerTypeEnum, string> = {
  [CustomerTypeEnum.PERSON]: 'Persona',
  [CustomerTypeEnum.COMPANY]: 'Empresa'
}

export interface IAddress {
  region: string,
  sub_region: string,
  locality: string,
  address: string,
  postal_code?: string
}

export interface IContact {
  address?: IAddress,
  phone?: string,
  email?: string
}

export interface ICustomer {
  customer_id: number;
  code: string;
  customer_type: CustomerTypeEnum;
  cod_type_ident: IdentificationTypeEnum;
  identification: string;
  with_ruc: boolean;
  first_name?: string;
  last_name?: string;
  gender: GenderTypeEnum;
  birth_date?: Date;
  commercial_name?: string;
  contact_info: IContact,
  notes?: string;
}

export interface ISearchCustomer extends ISearchResult {
  identification: string;
  customer_type: string;
  cod_type_ident: string;
  with_ruc: string;
}

export class Customer implements ICustomer {
    customer_id: number;
    code: string;
    customer_type: CustomerTypeEnum;
    cod_type_ident: IdentificationTypeEnum;
    identification: string;
    with_ruc: boolean;
    first_name?: string;
    last_name?: string;
    gender: GenderTypeEnum;
    birth_date?: Date;
    commercial_name?: string;
    contact_info: IContact;
    notes?: string;

    constructor(data: ICustomer) {
      this.customer_id = data.customer_id;
      this.code = data.code;
      this.customer_type = data.customer_type;
      this.cod_type_ident = data.cod_type_ident;
      this.identification = data.identification;
      this.with_ruc = data.with_ruc;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.gender = data.gender;
      this.birth_date = data.birth_date;
      this.commercial_name = data.commercial_name;
      this.contact_info = data.contact_info;
      this.notes = data.notes;
    }

    get fiscalIdentification(): string {
      if (this.with_ruc) {
        return `${this.identification}001`;
      } else {
        return this.identification;
      }
    }

    get fullName(): string {
      if (this.customer_type === CustomerTypeEnum.PERSON) {
        return `${this.first_name} ${this.last_name}`.trim();
      } else {
        return `${this.commercial_name}`.trim();
      }
    }

    get address(): string {
      return this.contact_info.address?.address || '';
    }

    get email(): string {
      return this.contact_info.email || '';
    }

    get phone(): string {
      return this.contact_info.phone || '';
    }

}
